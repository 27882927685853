<template>
  <div class="pt-1">
    <div>
      <div class="card p-2">
        <div class="d-flex justify-content-between align-items-center">
          <div class="saj-title">
            {{ $t('Company List') }}
            <!-- {{ companyList }} -->
          </div>
          <div
            class="saj-title d-flex"
          >
            <b-form-input
              v-model="searchCompany"
              style="width: 250px;"
              :placeholder="$t('Search Company')"
              :style="roles.isHR ? `display: none;` : ``"
              size="md"
              class="saj-text"
            />
            <b-button
              variant="primary"
              class="ml-1 saj-button"
              block
              :style="roles.isHR ? `display: none;` : ``"
              @click="createCompany()"
            >
              {{ $t('Add Company') }}
            </b-button>
          </div>
        </div>
        <hr>
        <b-overlay
          :show="show"
          rounded="sm"
        >

          <template #overlay>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <b-spinner
                variant="primary"
                label="Spinning"
              />
              <p class="mt-1">
                {{ $t('Fetching Data') }}...
              </p>
            </div>

          </template>
          <b-table
            bordered
            :items="companyList"
            :fields="fields"
            :filter="searchCompany"
            :current-page="currentPage"
            :per-page="perPage"
            label-size="lg"
            responsive
            @filtered="onFiltered"
          >
            <template #head()="data">
              <span
                class="saj-text"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #cell(index)="data">
              <div class="saj-text d-flex justify-content-center">
                {{ currentPage === 1 ? data.index + 1 : data.index + 1+((currentPage-1) *10) }}
              </div>
            </template>
            <template #cell(subsidiary_name)="data">
              <div class="saj-text d-flex justify-content-start">
                {{ data.item.subsidiary_name }}
              <!-- {{ data.item.id }} -->
              </div>
            </template>
            <template #cell(description)="data">
              <div
                v-if="data.item.description === null"
                class="saj-text d-flex justify-content-start"
              >
                -
              </div>
              <div
                v-else
                class="saj-text d-flex justify-content-start"
              >
                {{ data.item.description === 'null' || data.item.description === null ? "-" : data.item.description }}
              </div>
            </template>
            <template #cell(total_work_location)="data">
              <div class="saj-text d-flex justify-content-center">
                {{ data.item.total_work_location }}
              </div>
            </template>
            <template #cell(number_of_staff)="data">
              <div class="saj-text d-flex justify-content-center">
                {{ data.item.number_of_active_staff }}
              </div>
            </template>
            <template #cell(action)="data">
              <div
                class="saj-text d-flex justify-content-center"
                style="
          "
              >
                <div
                  class=""
                  @click="viewCompany(data.item.id, data.item.subsidiary_name)"
                >
                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Edit')"
                    :comp-data="companyList"
                    icon="EyeIcon"
                    class="hoverIcon"
                    size="25"
                  />
                </div>
                <div
                  v-if="roles.selectedRole === 1"
                  class="ml-1"
                  @click="assignTempDelete(data.item.id)"
                >

                  <feather-icon
                    v-b-tooltip.hover.bottom="$t('Delete')"
                    icon="Trash2Icon"
                    class="hoverIcon"
                    style="color: red"
                    size="25"
                  />
                </div>
              </div>
            </template>
          </b-table>
          <b-row class="mt-1">
            <b-col>
              <span
                class="saj-text"
                align="start"
              >
                {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }}
                <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
              </span>
            </b-col>
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                pills
                aria-controls="employee-table"
                class="saj-text mb-1 justify-content-end"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-overlay>
      </div>
    </div>
    <b-modal
      v-model="showDeleteCompany"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        :title="$t('Are you sure you want to delete') + '?'"
        @cancel="showDeleteCompany = false"
        @confirm="deleteCompany()"
      />
    </b-modal>
  </div>
</template>

<style scoped>
.hoverIcon{
  color: black;
  cursor: pointer;
}

.hoverIcon:hover{
  color: red;
  cursor: pointer;
}
</style>

<script>
import {
  BButton,
  BTable,
  BFormInput,
  BOverlay,
  BSpinner,
  VBTooltip,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue"
import AlertModal from '@/pages/alert-modal.vue'
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BTable,
    BFormInput,
    AlertModal,
    BOverlay,
    BSpinner,
    BPagination,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      subsidiary_id: null,
      show: true,
      showDeleteCompany: false,
      tempIDToDelete: null,
      searchCompany: null,
      targetDate: null,
      total_company: null,
      total_work_location: null,
      number_of_staff: null,
      currentPage: 1,
      perPage: 10,
      rows: null,
      companyList: [],
      subsidiaryList: [],
      fields: [
        {
          key: 'index', label: 'No',
        },
        {
          key: 'subsidiary_name', label: 'Company Name', thClass: 'text-center',
        },
        {
          key: 'description', label: 'Description', thClass: 'text-center',
        },
        {
          key: 'total_work_location', label: 'Total Work Location', thClass: 'text-center',
        },
        {
          key: 'number_of_staff', label: 'Number of Employee', thClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', thClass: 'text-center',
        },

      ],

    }
  },
  computed: {
    ...mapGetters(['roles']),
  },
  watch: {
    $route: {
      handler(){
        this.getSub_Id()
      },

    },
  },
  mounted(){
    this.getSub_Id()

    // this.getDetails()
  },
  methods: {
    onFiltered(filteredItems){
      this.rows = filteredItems.length
    },
    getSub_Id() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        this.subsidiary_id = response.data.data.employee.business_unit
        this.checkUser()
        // console.log("siniii sub id", this.subsidiary_id)
      })
    },
    checkUser() {
      const currentRole = this.roles.selectedRole
      if (currentRole === 6){
        this.getDetailsHR()
      } else if (currentRole === 1){
        this.getDetails()
      }
    },
    assignTempDelete(id) {
      this.showDeleteCompany = true
      this.tempIDToDelete = id
    },
    deleteCompany() {
      const data = new FormData()
      data.append('id', this.tempIDToDelete)

      const config = {
        method: 'post',
        url: `${this.$baseUrl}/subsidiary/delete`,
        data,
      }

      this.$axios(config)
        .then(() => {
          this.showDeleteCompany = false
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully deleted')}!`,
                icon: 'Trash2Icon',
              variant: 'success',
              iconBg: '#e80202',
              titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.getDetails()
        })
        .catch(error => {
          // console.log('message:', error.response.data.message)
          // text: `${this.$t('Please fill all required fields')}!`,
          this.$toast(
            {
              component: SAJToast,
              props: {
                text: error.response.data.message,
                icon: 'XIcon',
                iconBg: '#e80202',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        })
    },
    getDetails() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`, data).then(response => {
        if (response.data.data.length !== 0){
          this.show = false
          this.companyList = response.data.data.subsidiaries
          this.companyList.forEach(list => {
            this.subsidiaryList.push(list.id)
            // console.log("subsidiary id ", list.id)
          })
          // console.log(response)
          // console.log("subsidiary id list", this.subsidiaryList)
        }

        this.rows = this.companyList.length

        // console.log('education masukkkk', response)
      }).catch(() => {
        this.show = false
      })
    },
    getDetailsHR() {
      // console.log()
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll?id=${this.subsidiary_id}`).then(response => {
        if (response.data.data.length !== 0){
          this.show = false
          this.companyList = response.data.data.subsidiaries
          this.companyList.forEach(list => {
            this.subsidiaryList.push(list.id)
            // console.log("subsidiary id ", list.id)
          })
          // console.log(response)
          // console.log("subsidiary id list", this.subsidiaryList)
        }

        // console.log('education masukkkk', response)
      }).catch(() => {
        this.show = false
      })
    },
    createCompany() {
      const data = new FormData()
      this.$axios.post(`${this.$baseUrl}/subsidiary`, data).then(response => {
        const newId = response.data.data.id

        this.$router.push({ name: 'register-staff', params: { id: newId, isCreate: true } })
      })
    },
    viewCompany(id, subsidiaryname) {
      this.$router.push({ name: 'view-company', params: { id, subsidiaryname } })
      // console.log(id, subsidiaryname)
    },

  },
}
</script>
